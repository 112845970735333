import "./styles.less";

import { Affix, Col, Row } from "antd";
import {
	BannerAffix,
	BannerTopCover,
	PerfilBreadcrumb,
	PerfilButtonContacts,
	PerfilInfoInmob,
	PerfilProperties,
	PerfilSubsidiary,
} from "../../../../src/Components/Inmobiliarias/Perfil/perfilComponents";
import React, { useContext, useEffect, useState } from "react";

import { PageLayout } from "../../../../src/Components/Layout/web";
import { PerfilSkeleton } from "../../../../src/Components/Inmobiliarias/Perfil/perfilSkeleton";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useRealEstateSingle } from "../../../../src/Components/Inmobiliarias/Perfil/hook";
import { useRouter } from "next/router";
import { useTheme } from "../../../../shared-components/Styles/ThemeHook";
import { withApollo } from "../../../../lib/apollo3";
import { withAuth } from "../../../../lib/auth";
import { withContext } from "../../../../lib/context";
import { withGlobalComponents } from "../../../../shared-components/ViewFragments/GlobalComponents/web";
import { withTranslation } from "../../../../shared-components/i18n";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { fetchSearchResults } from "src/services/search";
import { Listing } from "shared-components";

// Page Perfil
const Perfil = () => {
	const { theme } = useTheme();
	const screen = useBreakpoint();
	const { inmob, loading, error, contact, inmobId } = useRealEstateSingle();
	const router = useRouter();
	const { country_code } = useContext(ConfigStateContext);
	const [properties, setProperties] = useState<Listing[]>([])

	useEffect(() => {
		const payload = {
			rows: 6,
			params: {
				owner_id: inmobId,
			},
			page: 1,
			source: 10,
		};

		fetchSearchResults(payload)
			.then((res: any) => {
				const data = res?.searchFast?.data;
				setProperties(data);
			});
	}, [])

	useEffect(() => {
		if (!loading && !inmob) {
			router.push("/");
		}
	}, [loading, inmob]);

	if (error) return null;
	if (loading || !inmob || !country_code) return <PerfilSkeleton />;

	return (
		<PageLayout classContainer="perfil-inmob-container">
			{/* Banner Top Cover */}
			{!screen.xs && <BannerTopCover inmob={inmob} />}
			{/* Content */}
			<div className="content-container">
				<Affix className="perfil-fidex-top" offsetTop={theme.headerHeight}>
					<div className="container">
						{/* Banner Affix */}
						<BannerAffix inmob={inmob} />
					</div>
				</Affix>

				<div className="perfil-content">
					<Row
						gutter={[0, theme.spacing.xlSpacing]}
						style={{
							padding: `${theme.spacing.xlSpacing}px 0`,
						}}>
						{/* Breadscrum */}
						<Col span={24}>
							<div className="container">
								<PerfilBreadcrumb inmob={inmob} />
							</div>
						</Col>
						{/* Info Real Estate */}
						{inmob.description && (
							<Col span={24}>
								<div className="container">
									<PerfilInfoInmob inmob={inmob} />
								</div>
							</Col>
						)}
						{/* Subsidiary */}
						<Col span={24}>
							<div className="container">
								<PerfilSubsidiary inmob={inmob} />
							</div>
						</Col>
						{/* Properties */}
						<Col span={24}>
							{!!properties.length &&
								<div className="container">
									<PerfilProperties properties={properties} inmob={inmob} />
								</div>
							}
						</Col>
						{/* Buttons Bottom Contact */}
						{process.env.APP_THEME != "fincaraiz" &&
							<Col span={24}>
								<div className="container">
									<PerfilButtonContacts inmob={inmob} contact={contact} />
								</div>
							</Col>
						}
					</Row>
				</div>
			</div>
			<style jsx global>{`
				.perfil-fidex-top, .ant-affix {
					background: #077be7;
				}

				.perfil-fidex-top .perfil-info h1 {
					color: #fff !important;
				}
			`}</style>
		</PageLayout>
	);
};

export default withApollo(
	withContext(withTranslation(withAuth(withGlobalComponents(Perfil))), {
		withDummyContext: false,
		withFiltersContext: false,
		withConfigContext: true,
		withThemeContext: true,
	})
);
